import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Container from 'react-bootstrap/Container'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilePdf } from "@fortawesome/free-regular-svg-icons"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Privacy from "../components/Privacy"

const PrivacyPage = () => (
  <Layout>
    <SEO title="Privacy" />
    <Container className="my-4">
      <h1 className="text-primary">Privacy</h1>
      <Row>
        <Col xl={{span: 5, offset: 0, order: 'last'}} lg={{span: 6, offset: 0, order: 'last'}} xs={{span: 8, offset: 2, order: 'first'}} className="pl-lg-5">
          <Privacy/>
        </Col>
        <Col xl={7} lg={6}>
          <p>
            The General Data Protection Regulation (GDPR), together with the Data Protection Act 2018 (DPA, 2018) aims to ensure the privacy of personal data, and places responsibility on those who collect and process personal data to ensure they do so with lawfulness, fairness and transparency.
          </p>
          <p>
            Waite Psychology takes data protection very seriously, and practices within professional codes of conduct, ensuring the purpose of data collection is considered, and how data is handled and monitored. The following document sets out how we achieve this.
          </p>
          <p className="text-primary"><a href="../../PrivacyNotice.pdf" download><FontAwesomeIcon icon={faFilePdf} className="mr-2"/>Privacy notice for schools and families.</a></p>
          <p>
            Please do not hesitate to contact us at <a href="mailto:jennifer@waitepsychology.co.uk">jennifer@waitepsychology.co.uk</a> or <a href="mailto:michael@waitepsychology.co.uk">michael@waitepsychology.co.uk</a> for further information, or if you have any concerns  about any data held.
          </p>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default PrivacyPage
